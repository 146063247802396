const { REACT_APP_WORDPRESS_API } = process.env

class Wordpress {
  constructor() {
    this.apiUrl = REACT_APP_WORDPRESS_API
    this.numPosts = 10
  }

  async getByUID(type, id) {
    const endpoint = `${type}?slug=${id}&acf_format=standard&_fields=acf,id`
    const { data } = await this.fetchFromWordpress(endpoint)
    return data
  }

  async getSingle(id) {
    const endpoint = `pages?slug=${id}&_fields=acf`
    const { data } = await this.fetchFromWordpress(endpoint)
    return data
  }

  async getAllByType(type) {
    const endpoint = `${type}?per_page=${this.numPosts}&acf_format=standard`
    const { data, nbrPages } = await this.fetchFromWordpress(endpoint)
    const allPosts = [...data]

    const promises = []

    if (nbrPages > 1) {
      for (let index = 1; index < nbrPages; index += 1) {
        const res = this.fetchFromWordpress(
          `${endpoint}&_fields=acf,id&page=${index + 1}`
        )
        promises.push(res)
      }
    }

    const results = await Promise.all(promises)
    results.forEach((element) => {
      allPosts.push(...element.data)
    })

    return allPosts
  }

  async fetchFromWordpress(endpoint) {
    try {
      const response = await fetch(`${this.apiUrl}/${endpoint}`)
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`)
      }

      const nbrPages = response.headers.get("X-WP-TotalPages")
      const json = await response.json()

      return { data: json, nbrPages }
    } catch (error) {
      console.error(error.message)
    }
    return null
  }
}

const client = new Wordpress()

export default client
