import React, { useEffect, useRef, useState } from "react"
import { isMobile } from "react-device-detect"
import { useLocation } from "react-router-dom"
import Navigation from "./Navigation"

const { REACT_APP_MEDIA_URL } = process.env

function ScrollToButton({ scrollRef }) {
  const clickToScroll = () => {
    if (!isMobile) {
      scrollRef.current.scrollTo(window.innerHeight)
    } else {
      window.scrollTo({
        top: window.innerHeight,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  return (
    <div className="c-icon-scroll">
      <button
        type="button"
        className="icon icon-scroll"
        onClick={() => clickToScroll()}
        aria-label="SCroll Down"
      />
    </div>
  )
}

function Cover(
  { loadingVideo, setLoadingVideo, scrollRef, video, image },
  ref
) {
  const videoCover = useRef(null)
  const [muted, setMuted] = useState(true)

  const handleMutedSound = (state) => {
    videoCover.current.muted = !state
    setMuted(!state)
  }

  useEffect(() => {
    // const btn = document.querySelector(".play-btn")

    if (videoCover.current) {
      videoCover.current.addEventListener("playing", () => {
        // console.log("is playing !")
        setLoadingVideo(false)
      })

      videoCover.current.play()
      document.body.addEventListener(
        "touchstart",
        () => {
          if (videoCover.current) {
            videoCover.current.play()
          }
        },
        false
      )
    }
  }, [])

  useEffect(() => {
    const coverRef = ref
    if (coverRef !== null) {
      coverRef.current = {
        setMuted: (a) => {
          handleMutedSound(a)
        },
        muted: () => muted,
      }
    }
  }, [muted, ref])

  const location = useLocation()
  useEffect(() => {
    const btn = document.querySelector(".play-btn")
    if (scrollRef.current && btn) {
      scrollRef.current.on("scroll", (args) => {
        if (args.scroll.y > window.innerHeight) {
          btn.classList.add("show")
        } else {
          btn.classList.remove("show")
        }
      })
    }
  }, [scrollRef])

  return (
    <section
      id="cover"
      className={`${!video ? "cover-project" : ""} s-hero-header`}
      data-scroll-section
      data-scroll-speed="-8"
      data-scroll-position="top"
    >
      {video !== undefined && (
        <button
          className="play-btn"
          type="button"
          onClick={() => handleMutedSound(muted)}
        >
          <span>Sound</span>
          <div className="icon-play">
            <div className={`sound ${muted ? "sound-mute" : ""}`}>
              <div className="sound--icon fa fa-volume-off" />
              <div className="sound--wave sound--wave_one" />
              <div className="sound--wave sound--wave_two" />
            </div>
          </div>
        </button>
      )}

      <div className="c-hero-header clip">
        <div className="w-hero-header" data-scroll>
          <ScrollToButton scrollRef={scrollRef} />

          <div className="hero-header-bg-target-fixed" id="fixed-target" />

          <div
            className="hero-header-bg"
            data-scroll
            data-scroll-sticky
            data-scroll-target="#fixed-target"
          >
            {video !== undefined && (
              <video
                muted
                disableRemotePlayback
                loop
                playsInline
                autoPlay
                ref={videoCover}
                preload="true"
              >
                <source src={video} type="video/mp4" />
              </video>
            )}
            {loadingVideo && (
              <img
                className="video-loader"
                src={`${REACT_APP_MEDIA_URL}/loader.gif`}
                alt="loader"
              />
            )}
            <picture>
              <source srcSet={`${image}`} type="image/jpg" />
              <img
                // style={{ display: "none" }}
                className="img-cover"
                alt=""
                src={`${image}`}
                onLoad={() => {
                  const time = location.pathname !== "/" ? 0 : 1200

                  setTimeout(() => {
                    const loader = document.querySelector("#loader")
                    loader.classList.remove("show")
                  }, time)
                }}
              />
            </picture>
          </div>

          <Navigation
            scrollRef={scrollRef}
            scrollTarget="#cover"
            color="white"
          />
        </div>
      </div>
    </section>
  )
}
// eslint-disable-next-line no-func-assign
Cover = React.forwardRef(Cover)

export default Cover
