import React, { createContext, useContext, useState } from "react"

const ConfigContext = createContext({})
const { Provider, Consumer } = ConfigContext

function ConfigProvider({ children, ...props }) {
  const [loadingVideo, setLoadingVideo] = useState(true)

  return (
    <Provider value={{ loadingVideo, setLoadingVideo }} {...props}>
      {children}
    </Provider>
  )
}

const useConfigProvider = () => {
  const state = useContext(ConfigContext)
  if (state === undefined) {
    throw new Error("useConfigContext must be called within YourProvider")
  }

  return {
    ...state,
  }
}

export { ConfigProvider, Consumer as YourConsumer, useConfigProvider }

export default ConfigContext
