import React from "react"
import { Link, useLocation } from "react-router-dom"

const { REACT_APP_MEDIA_URL } = process.env

function Navigation({ ...props }) {
  const style = props.color === "black" ? "nav-black" : "nav-white"
  const zIndex = props.zindex === undefined ? "z-1" : props.zindex
  const { pathname } = useLocation()

  return (
    <div
      className={`main-menu ${style} ${zIndex}`}
      data-scroll
      data-scroll-target={props.scrollTarget}
      data-scroll-sticky
    >
      <div className="c-logo">
        <Link
          to="/"
          state={{ scrollTo: "#cover", time: pathname === "/" ? 0 : 1500 }}
        >
          <img alt="" src={`${REACT_APP_MEDIA_URL}/logo.svg`} />
        </Link>
      </div>

      <ul>
        <Link
          className="link noSelect"
          to="/"
          state={{ scrollTo: "#studio", time: pathname === "/" ? 0 : 2000 }}
        >
          studio
        </Link>

        <Link
          className="link noSelect"
          to="/"
          state={{
            scrollTo: "#selectedworks",
            time: pathname === "/" ? 0 : 2000,
          }}
        >
          works
        </Link>

        <Link
          className="link noSelect"
          to="/"
          state={{ scrollTo: "#contact", time: pathname === "/" ? 0 : 2000 }}
        >
          contact
        </Link>
      </ul>
    </div>
  )
}

export default Navigation
