import React, { useEffect } from "react"
import { motion } from "framer-motion"
import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import LoaderAnime from "../../components/LoaderAnime"

import { SetPageName } from "../../helpers"

function Legals(props) {
  const metaTitle = "Website Legal Mentions"
  const metaDescription =
    "All creations are the property of the Blue Node studio. Contact-us to have more informations."

  useEffect(() => {
    setTimeout(() => {
      const loader = document.querySelector("#loader")
      loader.classList.remove("show")
    }, 1000)
  }, [])

  return (
    <>
      <LoaderAnime />

      <SetPageName metaTitle={metaTitle} metaDescription={metaDescription} />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <section
          data-scroll-section
          data-scroll-section-id="section-big"
          className="section-container section"
        >
          <div id="s-big-sentences-clone" className="section-clone clip">
            <Navigation
              {...props}
              scrollTarget="#s-big-sentences-clone"
              color="black"
              zindex="z-1"
            />
          </div>

          <div className="max-container">
            <div className="c-legals-terms pt-18 pb-18">
              <h1>Legals Terms</h1>
              <h2>Website publisher</h2>
              <p>
                Blue Node is the website owner of www.bluenode.paris. You can
                contact the website creators via the following email address;
                contact@bluenode.paris.
              </p>

              <h2>Hosting conditions</h2>
              <p>
                website www.bluenode.paris is hosted by Amazon Web Services:
                company name : Amazon CS Ireland Ltd Adress : Unit 27 – 6400
                Cork Airport Business Park – Kinsale Road – Ireland Amazon CS
                Ireland Ltd is an Amazon Web Services (AWS) company, the head
                office in Inc. P.O/ Box 81226 – Seattle, WA 98108-1226, tél :
                (206) 266-4064 fax: (206) 266-7010
              </p>

              <h2>Service provided description</h2>
              <p>
                Blue Node website is a portfolio of Blue Node’s various actions,
                achievements and projects.
              </p>

              <h2>Intellectual property</h2>
              <p>
                The source code, graphics, images, sounds, animations, texts, as
                well as the information and contents of www.bluenode.paris are
                protected by intellectual property copyrights. The total or
                partial reproduction of this website is prohibited without prior
                express written authorization.
              </p>

              <h2>Personal data managment</h2>
              <p>
                Personal data are protected by the law n° 78-87 of January 6,
                1978, the law n° 2004- 801 of August 6, 2004, the article L.
                226-13 of Penal Code and European Directive of October 24, 1995.
                The site is not declared to the CNIL because it does not collect
                personal information.
              </p>

              <h2>Applicable law and jurisdiction attribution</h2>
              <p>
                Any dispute in connection with the use of the site is subject to
                French law. The competent courts of Paris have exclusive
                jurisdiction.
              </p>
            </div>
          </div>
        </section>

        <Footer {...props} background="bg-white" />
      </motion.div>
    </>
  )
}

export default Legals
