import React from "react"
import { motion } from "framer-motion"

import WorksList from "../../components/WorksList"

function SelectedWorks({ cursor }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <WorksList highlight cursor={cursor} color="black" />
    </motion.div>
  )
}

export default SelectedWorks
