import { useEffect } from "react"
import { usePresence } from "framer-motion"
// import { useLocation } from "react-router-dom"

function LoaderAnime() {
  // const location = useLocation()
  const [isPresent, safeToRemove] = usePresence(false)

  useEffect(() => {
    const loader = document.querySelector("#loader")

    if (!isPresent) {
      setTimeout(safeToRemove, 1200)
    }
    if (loader !== null) {
      setTimeout(() => {
        if (!isPresent) {
          loader.classList.add("show")
        } else {
          // loader.classList.remove("show")
        }
      }, 0)
    }
  }, [isPresent])
}

export default LoaderAnime
