import { useEffect } from "react"

function CursorLoop({ cursor }) {
  useEffect(() => {
    const mouseSide = (mouse) => {
      const x =
        mouse.x > window.innerWidth / 2 - 320
          ? window.innerWidth / 80 - 7
          : window.innerWidth / 80

      const y =
        mouse.y > window.innerHeight / 2
          ? -window.innerWidth / 120
          : window.innerWidth / 400
      return { x, y }
    }

    const mouse = { x: 300, y: 300 }
    const pos = { x: 0, y: 0 }
    const speed = 0.04 // between 0 and 1

    const updatePosition = () => {
      pos.x += (mouse.x - pos.x) * speed + mouseSide(mouse).x
      pos.y += (mouse.y - pos.y) * speed + mouseSide(mouse).y

      const cursorPos = cursor
      if (cursor.current !== null) {
        cursorPos.current.style.transform = `translate3d(${pos.x}px ,${pos.y}px, 0)`
      }
    }

    const updateCoordinates = (e) => {
      mouse.x = e.clientX - 320
      mouse.y = e.clientY - 50
    }

    window.addEventListener("mousemove", updateCoordinates)

    function loop() {
      updatePosition()
      requestAnimationFrame(loop)
    }
    requestAnimationFrame(loop)
  }, [])
}

export default CursorLoop
