import React, { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import { motion } from "framer-motion"
import { isMobile } from "react-device-detect"
import client from "../../wordpress"
import { SetPageName } from "../../helpers"
import Cover from "../../components/Cover"
import LoaderAnime from "../../components/LoaderAnime"
import Footer from "../../components/Footer"

function ProjectInfo({ clientId, coproduction, roles, credits }) {
  const clientContent = clientId !== null ? <li>{clientId}</li> : null

  const coproductionContent =
    coproduction !== "" && coproduction !== undefined ? (
      <li>{coproduction}</li>
    ) : null

  const rolesContent =
    roles.role !== null
      ? roles.map((item) => <li key={item.role}>{item.role}</li>)
      : null

  const creditsContent =
    credits.credit_type !== null
      ? credits.map((credit) => (
          <li key={credit.credit_descr}>
            {credit.credit_type} : {credit.credit_descr}
          </li>
        ))
      : null

  let counter = 0

  const array = [
    { title: "Client", content: clientContent },
    { title: "coproduction", content: coproductionContent },
    { title: "Role", content: rolesContent },
    { title: "Credits", content: creditsContent },
  ]

  return (
    <>
      {array.map((item) => {
        counter += item.content !== null && 1
        return (
          <div key={`title-${item.title}`}>
            {item.content !== null && (
              <div className="item" key={`content-${item.title}`}>
                <h3>
                  <span>{`0${counter}`}</span> {item.title}
                </h3>
                {item.content}
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

function SingleProject({ scrollRef, references }, props) {
  const [nextProjectUrl, setNextProjectUrl] = useState("null")
  const [prevProjectUrl, setPrevProjectUrl] = useState("null")

  const [keyProject, setKeyProject] = useState(null)
  const { id } = useParams()

  const [reference, setReference] = useState({
    title: "",
    summary: "",
    metaTitle: "",
    metaDescription: "",
    client: "",
    coproduction: "",
    url: "",
    video: null,
    videoPreview: "",
    technicalSkills: "",
    credits: [],
    roles: [],
  })

  useEffect(() => {
    async function fetchData() {
      const wordpressDoc = await client.getByUID("project", id)
      const { acf } = wordpressDoc[0]
      setReference({
        ...acf,
      })
    }
    fetchData()
  }, [id])

  useEffect(() => {
    const scrollAvailable = scrollRef.current !== null
    if (scrollAvailable) {
      scrollRef.current.update()
    }
  }, [scrollRef, reference])

  useEffect(() => {
    references.filter((obj, key) => {
      if (obj.url === id) {
        setKeyProject(key)

        const prevKey = key - 1 < 0 ? references.length - 1 : key - 1
        const nextKey = references.length !== key + 1 ? key + 1 : 0

        setPrevProjectUrl(`/projects/${references[prevKey].url}`)
        setNextProjectUrl(`/projects/${references[nextKey].url}`)
      }
      return null
    })
  }, [id, references])
  return (
    <>
      <LoaderAnime />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { delay: 1 } }}
        transition={{ duration: 0 }}
      >
        <div>
          {reference.meta_title && reference.meta_description && (
            <SetPageName
              metaTitle={reference.meta_title}
              metaDescription={reference.meta_description}
            />
          )}
          {reference.cover && (
            <Cover image={reference.cover} scrollRef={scrollRef} />
          )}

          <section
            data-scroll-section
            data-scroll-section-id="section-big"
            className="section section-single-project "
          >
            <div className="max-container z-10" id="nav-sticky">
              <motion.div
                className="c-project-navigation"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div
                  className="project-navigation"
                  data-scroll
                  data-scroll-target="#nav-sticky"
                  data-scroll-sticky
                  data-scroll-offset="-100"
                >
                  <Link className="link noSelect" to={prevProjectUrl}>
                    ‹ Prev
                  </Link>

                  <Link
                    className="link noSelect"
                    to="/"
                    state={{
                      scrollTo: isMobile
                        ? `#project-${keyProject}`
                        : `#selectedworks`,
                      time: 1500,
                    }}
                  >
                    All works
                  </Link>

                  <Link className="link noSelect" to={nextProjectUrl}>
                    Next ›
                  </Link>
                </div>
              </motion.div>

              <div
                className="c-project-intro"
                data-scroll
                data-scroll-speed="1"
              >
                <h1>{reference.title}</h1>
                <p>{reference.summary}</p>
                {reference.technical_tags && (
                  <div className="c-technical-tags">
                    <p>Cooked with :</p>
                    <div className="w-technical-tags">
                      {reference.technical_tags.map((item) => (
                        <span key={item.technical_tag}>
                          {item.technical_tag}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="c-project-info">
                <div className="c-flex">
                  <ProjectInfo
                    clientId={reference.client}
                    coproduction={reference.coproduction}
                    roles={reference.roles}
                    credits={reference.credits}
                  />
                </div>

                <div
                  className="fixed-target-single-project"
                  id="fixed-target-single-project"
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div
                      data-scroll
                      data-scroll-sticky
                      data-scroll-target="#fixed-target-single-project"
                      data-scroll-offset="-100"
                    >
                      {reference.video !== null && (
                        <video
                          disableRemotePlayback
                          autoPlay={!isMobile}
                          muted
                          playsInline
                          loop
                          controls
                        >
                          <source src={reference.video} type="video/mp4" />
                        </video>
                      )}
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer {...props} background="bg-white" />
      </motion.div>
    </>
  )
}

export default SingleProject
