import React, { useRef, useEffect, useState } from "react"
import { install } from "resize-observer"
import { Routes, Route, useLocation, Navigate } from "react-router-dom"
import { LocomotiveScrollProvider } from "react-locomotive-scroll"
import { AnimatePresence } from "framer-motion"
import { ConfigProvider } from "./contexts/config-context"
import client from "./wordpress"
import Home from "./pages/Home/Home"
import Legals from "./pages/Legals/Legals"
import WorksList from "./pages/SelectedWorks/SelectedWorks"
import "./styles/index.scss"
import SingleProject from "./pages/SingleProject/SingleProject"
import { scrollToAnchor } from "./helpers"

const { REACT_APP_MEDIA_URL } = process.env

install()

function App() {
  const [references, setReferences] = useState([])

  const [activeEffect, setActiveEffect] = useState(false)
  const [eventListen, setEventListen] = useState(false)

  const refChar = useRef(null)

  const containerRef = useRef(null)
  const location = useLocation()
  const { pathname } = useLocation()
  const scrollRef = useRef(null)
  const cursor = useRef(null)

  const props = {
    cursor,
    scrollRef,
  }

  useEffect(() => {
    async function fetchData() {
      const getWorkist = await client.getSingle("homepage")
      const getProjects = await client.getAllByType("project")

      const sortedProjects = []

      const { projects } = getWorkist[0].acf

      projects.forEach((projectId) => {
        const found = getProjects.find((element) => element.id === projectId)
        sortedProjects.push(found.acf)
      })

      setReferences(sortedProjects)
    }
    fetchData()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }, [pathname])

  useEffect(() => {
    if (location.state !== null) {
      const { time } = location.state
      setTimeout(() => {
        scrollToAnchor(location.state.scrollTo, scrollRef, 100)
      }, time)
    }
  }, [location])

  const handleAppHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  useEffect(() => {
    handleAppHeight()
  }, [])

  return (
    <div className="App">
      <div
        className={`c-loader show ${pathname === "/" && "c-home-loader"}`}
        id="loader"
      >
        <img
          className="black-loader"
          alt=""
          src={`${REACT_APP_MEDIA_URL}/Bwhite1.svg`}
        />
        <img
          className="white-loader"
          alt=""
          src={`${REACT_APP_MEDIA_URL}/Bwhite.svg`}
        />
      </div>

      <div className="c-mouseCursor" ref={cursor}>
        <div id="w-mouseCursor" className="w-mouseCursor" />
      </div>

      {
        <LocomotiveScrollProvider
          options={{
            smooth: true,
            smartphone: {
              smooth: false,
              // breakpoint:0,
              // inertia: 0,
              breakpoint: 767,
              getDirection: false,
            },
            tablet: {
              breakpoint: 0, // <---- Fixes The Issue 🎉
            },

            // ... all available Locomotive Scroll instance options
          }}
          watch={
            [
              // ..all the dependencies you want to watch to update the scroll.
              //  Basicaly, you would want to watch page/location changes
              //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
            ]
          }
          location={pathname}
          containerRef={containerRef}
          onLocationChange={(scroll) => {
            setActiveEffect(false)
            setTimeout(() => {
              scroll.scrollTo(0, { duration: 0, disableLerp: true })
            }, 500)
            scroll.update()
          }} // If you want to reset the scroll position to 0 for example
          onUpdate={(scroll) => {
            scrollRef.current = scroll
            scrollRef.current.on("call", (obj) => {
              // console.log(obj, state);
              switch (obj) {
                case "homeAnime":
                  setActiveEffect(true)
                  setEventListen(true)
                  break
                default:
              }
            })
          }} // Will trigger on
        >
          <main data-scroll-container ref={containerRef} id="containerRef">
            <div id="main-container">
              <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                  <Route
                    path="/"
                    element={
                      <ConfigProvider>
                        <Home
                          {...props}
                          refChar={refChar}
                          activeEffect={activeEffect}
                          setActiveEffect={setActiveEffect}
                          eventListen={eventListen}
                          references={references}
                        />
                      </ConfigProvider>
                    }
                  />
                  <Route
                    path="selected-works"
                    element={<WorksList {...props} />}
                  />
                  <Route path="legals" element={<Legals {...props} />} />

                  <Route
                    exact
                    path="/projects/:id"
                    element={
                      <SingleProject {...props} references={references} />
                    }
                  />

                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </AnimatePresence>
            </div>
          </main>
        </LocomotiveScrollProvider>
      }
    </div>
  )
}

export default App
