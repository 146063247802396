import { useEffect } from "react"

function Cursor({ scrollRef }) {
  useEffect(() => {
    const mouseCursor = document.querySelector(".c-mouseCursor")

    const mouse = { x: 0, y: 0 }
    const pos = { x: 0, y: 0 }
    const pos1 = { x: 0, y: 0 }

    const speed = 0.05 // between 0 and 1
    const speed1 = 0.02 // between 0 and 1

    const scrollSpeed = (scroll) =>
      scrollRef.current !== null && !Number.isNaN(scroll.current.current)
        ? scroll.current.current / window.innerHeight
        : 0

    const updatePosition = () => {
      pos.x += (mouse.x - pos.x - 85) * speed
      pos.y += (mouse.y - pos.y - 85) * speed

      pos1.x +=
        (mouse.x - pos1.x - window.innerWidth / 2) * speed1 +
        scrollSpeed(scrollRef)
      pos1.y +=
        (mouse.y - pos1.y - window.innerWidth / 2) * speed1 +
        scrollSpeed(scrollRef)

      if (mouseCursor !== null) {
        mouseCursor.style.transform = `translate3d(${pos.x}px, ${pos.y}px, 0)`
      }
    }

    const updateCoordinates = (e) => {
      mouse.x = e.clientX
      mouse.y = e.clientY
    }

    window.addEventListener("mousemove", updateCoordinates)

    function loop() {
      updatePosition()
      requestAnimationFrame(loop)
    }

    requestAnimationFrame(loop)
  }, [])
}

export default Cursor
