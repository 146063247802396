/* eslint-disable no-param-reassign */
import { motion } from "framer-motion"
import React from "react"
import { isMobile } from "react-device-detect"

const SvgBg = React.forwardRef(({ id }, ref) => {
  const variant = 1

  const animObject = [
    [
      "M448.5,311Q418,372,352,366.5Q286,361,230.5,420Q175,479,138.5,418Q102,357,79.5,303.5Q57,250,62.5,184Q68,118,137,116.5Q206,115,251,111Q296,107,341,129Q386,151,432.5,200.5Q479,250,448.5,311Z",
      "M418.5,284Q343,318,327.5,379.5Q312,441,257.5,418Q203,395,126.5,395.5Q50,396,35,323Q20,250,87,215Q154,180,171,120Q188,60,241.5,85.5Q295,111,336.5,134Q378,157,436,203.5Q494,250,418.5,284Z",
      "M461,321.5Q447,393,374.5,401Q302,409,253.5,398Q205,387,156.5,370Q108,353,115.5,301.5Q123,250,135.5,213Q148,176,172,129.5Q196,83,254.5,69Q313,55,340,110Q367,165,421,207.5Q475,250,461,321.5Z",
      "M399,304.5Q400,359,349.5,380.5Q299,402,236.5,443.5Q174,485,137.5,421.5Q101,358,89,304Q77,250,90.5,197Q104,144,142.5,90.5Q181,37,235.5,81.5Q290,126,371,114.5Q452,103,425,176.5Q398,250,399,304.5Z",
      "M448.5,311Q418,372,352,366.5Q286,361,230.5,420Q175,479,138.5,418Q102,357,79.5,303.5Q57,250,62.5,184Q68,118,137,116.5Q206,115,251,111Q296,107,341,129Q386,151,432.5,200.5Q479,250,448.5,311Z",
    ],
  ]

  function onUpdate() {
    // Correct Safari Bug
    const agent = navigator.userAgent || ""
    // eslint-disable-next-line no-bitwise
    if (~agent.indexOf("Safari") && agent.indexOf("Chrome") < 0 && !isMobile) {
      const mask = document.querySelectorAll(".w-sentence-path")

      // eslint-disable-next-line no-shadow
      mask.forEach((mask) => {
        mask.style.display = "none"
        // eslint-disable-next-line no-unused-vars
        const refreshElement = mask.offsetWidth

        mask.style.display = "block"
      })
    }
  }

  return (
    <svg
      className="svgMask"
      // viewBox="0 0 500 500"
      x="0px"
      y="0px"
      height="100%"
      width="100%"
    >
      <clipPath id={id} ref={ref} className="clipPath">
        <motion.path
          style={{
            fill: "#7BEABB",
          }}
          animate={{
            d: animObject[variant - 1],
          }}
          transition={{
            repeat: Infinity,
            ease: "easeInOut",
            duration: isMobile ? 0 : 5,
            times: [0, 0.16, 0.33, 0.5, 0.66, 0.83],
          }}
          onUpdate={onUpdate}
        />
      </clipPath>
    </svg>
  )
})

export default SvgBg
