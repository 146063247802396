import React, { useEffect } from "react"

import { Helmet } from "react-helmet-async"
import { isMobile } from "react-device-detect"

export const getUrlPath = () => {
  let urlPath = window.location.pathname
  urlPath = urlPath.replace("/", "")
  return urlPath
}

export function SetPageName({ metaTitle, metaDescription }) {
  useEffect(() => {
    if (metaTitle !== "" && metaTitle !== undefined) {
      window.gtag("event", "page_view", {
        page_title: `Blue Node | ${metaTitle}`,
        page_location: window.location.pathname + window.location.search,
      })
    }
  }, [metaTitle])
  return (
    <Helmet>
      <title>{`Blue Node | ${metaTitle}`}</title>
      <meta name="description" content={`${metaDescription}`} />
    </Helmet>
  )
}

export const scrollToAnchor = (id, scroll, offset) => {
  const el = document.querySelector(id)
  // console.log(el.offsetTop)

  const offsetState = id !== "#cover" ? offset : 0

  const getOffsetTop = (element) => {
    let offsetTop = 0

    while (element) {
      offsetTop += element.offsetTop
      // eslint-disable-next-line no-param-reassign
      element = element.offsetParent
    }
    return offsetTop
  }

  if (isMobile) {
    window.scroll({
      top: getOffsetTop(el),
      left: 0,
      behavior: "smooth",
    })
  } else if (scroll.current !== null) {
    scroll.current.scrollTo(el, {
      duration: 0,
      disableLerp: true,
      offset: offsetState,
    })
  }
}

export const mouseEffect = {
  getMouse() {
    return document.querySelector(".c-mouseCursor")
  },
  rotate(activeEffect, scroll, obj) {
    const scrollValue = scroll
    if (activeEffect && this.getMouse() !== null) {
      scrollValue.current = obj.scroll.y
      this.getMouse().children[0].style.transform = `rotate(${
        (Math.cos(Math.PI) * scroll.current) / 4
      }deg)`
    }
  },
  toggle() {
    setTimeout(() => {
      this.getMouse().style.display = "block"
      this.getMouse().classList.toggle("show")
    }, 1000)
  },
  hidden() {
    this.getMouse().style.display = "none"
    this.getMouse().classList.remove("show")
  },
}
