import React, { useRef, useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import { mouseEffect } from "../helpers"

import ContactInfos from "./ContactInfos"

import { clients } from "../data/data"
import CursorLoop from "./CursorLoop"
import Navigation from "./Navigation"

function ClientList({ section }) {
  const ulList = (
    <ul>
      {section.elements.map((item) => (
        <li key={`client-list-${item}`}>
          <p>{item}</p>
        </li>
      ))}
    </ul>
  )

  return (
    <>
      {ulList} {ulList} {ulList}
    </>
  )
}

function WorksList({
  color,
  highlight,
  loadingVideo,
  setLoadingVideo,
  references,
  ...props
}) {
  const cursor = useRef(null)
  const [isMouseOnSection, setIsMouseOnSection] = useState(false)
  const [videoSource, setVideoSource] = useState(null)

  useEffect(() => {
    const videoDiv = cursor.current
      ? cursor.current.querySelector(".cursorVideo")
      : null

    if (!isMobile && videoDiv.style !== null) {
      if (isMouseOnSection) {
        videoDiv.classList.add("show")
      } else {
        videoDiv.classList.remove("show")
      }
    }
  }, [isMouseOnSection])

  useEffect(() => {
    if (!isMobile) {
      const videoArray = cursor.current.querySelector(".cursorVideo")

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < videoArray.children.length; i++) {
        if (videoSource !== i) {
          const playPromise = videoArray.children[i].play()

          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                videoArray.children[i].pause()
              })
              .catch((error) => {
                console.log(error)
              })

            // videoArray.children[i].style.display = 'none'
            videoArray.children[i].style.opacity = 0
          }
        }

        if (videoSource !== null) {
          const video = videoArray.children[videoSource]
          if (video.paused) {
            video.play()
          }
          // video.style.display = 'block'
          video.style.opacity = 1
        }
      }
    }
  }, [videoSource, cursor])

  const styleBackground = color === "black" ? "bg-black" : "bg-white"
  const styleNav = color === "black" ? "white" : "black"

  // const selection = () => {
  //   const projectsHighlighted = projects().filter(
  //     (projet) => projet.highlight === highlight
  //   )
  //   if (highlight === true) {
  //     return projectsHighlighted
  //   }

  //   return projects()
  // }

  const cardVariants = {
    offscreen: {
      y: "-3rem",
      scale: 0.9,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.8,
      },
    },
    onscreen: {
      y: "8rem",
      scale: 1,

      // rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 0.8,
      },
    },
  }

  const cardVideoVariants = {
    offscreen: {
      y: "-11rem",
      scale: 0.9,
      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 0.8,
      },
    },
    onscreen: {
      y: "-11rem",
      scale: 1.2,

      // rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1.2,
      },
    },
  }

  const cardVideoVariantsHeight = {
    offscreen: {
      y: 0,
      height: "60%",
      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 1.2,
      },
    },
    onscreen: {
      y: 0,
      height: "90%",

      // rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 1.1,
      },
    },
  }

  return (
    <>
      {!isMobile && (
        <div id="cursor" ref={cursor} className="show">
          <div className="cursorVideo">
            {!loadingVideo &&
              references.map((projet) => {
                const { preview_poster, preview_video_loop, url } = projet
                return (
                  <video
                    key={`video-loop-desktop-${url}`}
                    disableRemotePlayback
                    muted
                    loop
                    playsInline
                    preload="none"
                    poster={preview_poster}
                  >
                    <source src={preview_video_loop} type="video/mp4" />
                  </video>
                )
              })}
          </div>
        </div>
      )}

      {!isMobile && (
        <CursorLoop isMouseOnSection={isMouseOnSection} cursor={cursor} />
      )}

      <div
        data-scroll-section
        className={`${styleBackground} section section-selected-works overflow-h z-15`}
      >
        <div className="max-container pt-12 pb-8 clients-list">
          {clients().map((section) => (
            <div key={`section-${section.title}`}>
              {!isMobile ? (
                <>
                  <h2>{section.title}</h2>

                  <div
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-speed="-4"
                    className="c-clients"
                    data-scroll-offset="0%,0%"
                  >
                    <div className="w-clients black">
                      <ClientList section={section} />
                    </div>
                  </div>

                  <div
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-speed="4"
                    className="c-clients"
                    data-scroll-offset="0%,0%"
                  >
                    <div className="w-clients offset">
                      <ClientList section={section} />
                    </div>
                  </div>

                  <div
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-speed="-4"
                    className="c-clients"
                    data-scroll-offset="0%,0%"
                  >
                    <div className="w-clients black offset-1">
                      <ClientList section={section} />
                    </div>
                  </div>

                  <div
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-speed="4"
                    className="c-clients"
                    data-scroll-offset="0%,0%"
                  >
                    <div className="w-clients black offset-2">
                      <ClientList section={section} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h2>{section.title}</h2>

                  <ul>
                    {section.elements.map((item) => (
                      <li key={`client-list-${item}`}>
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          ))}
        </div>

        <div
          id="section-selected-works-clone"
          className="section section-clone selected-works-clone clip"
        >
          <Navigation
            {...props}
            scrollTarget="#section-selected-works-clone"
            color={styleNav}
          />
        </div>

        <div className="max-container pt-12 works-list" id="selectedworks">
          <ul>
            <h2>Selected works</h2>

            {references.map((projet, index) => {
              const {
                preview_video_loop_mobile,
                preview_poster,
                type,
                category,
                title,
                url,
              } = projet
              return (
                <Link
                  to={`/projects/${url}`}
                  key={`projet-${url}`}
                  className="noSelect"
                >
                  {isMobile ? (
                    <motion.div
                      className="c-card"
                      initial="offscreen"
                      whileInView="onscreen"
                      exit="offscreen"
                      viewport={{ once: false, amount: 0.6 }}
                    >
                      <div className="splash" />

                      <li
                        onMouseEnter={() => setVideoSource(index)}
                        data-scroll
                        data-scroll-repeat
                        data-scroll-call={`{worksEvent,${index}}`}
                      >
                        <motion.div
                          variants={cardVariants}
                          className="c-details"
                          id={`project-${index}`}
                        >
                          <div className="title">
                            <span>
                              {index + 1 > 9 ? index + 1 : `0${index + 1}`}
                            </span>
                            <h2>{title}</h2>
                          </div>

                          <div className="details">
                            <span className="type">{type}</span>
                            <span className="category">{category}</span>
                          </div>
                        </motion.div>

                        <motion.div
                          className="w-video-card"
                          variants={cardVideoVariants}
                        >
                          <motion.div
                            className="w-video-card"
                            variants={cardVideoVariantsHeight}
                          >
                            <div className="w-video">
                              {!loadingVideo && (
                                <video
                                  key={`video-mobile-${url}`}
                                  disableRemotePlayback
                                  muted
                                  loop
                                  playsInline
                                  autoPlay
                                >
                                  <source
                                    src={preview_video_loop_mobile}
                                    type="video/mp4"
                                  />
                                </video>
                              )}

                              <img
                                alt="bluenode project preview"
                                type="image/jpg"
                                src={preview_poster}
                              />
                            </div>
                          </motion.div>
                        </motion.div>
                      </li>
                    </motion.div>
                  ) : (
                    <li
                      onMouseEnter={() => setVideoSource(index)}
                      onKeyDown={() => setVideoSource(index)}
                      onClick={() => mouseEffect.hidden()}
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                      role="button"
                      tabIndex={0}
                      data-scroll
                      data-scroll-repeat
                      data-scroll-call={`{worksEvent,${index}}`}
                    >
                      <div
                        className="title"
                        onMouseEnter={() => setIsMouseOnSection(true)}
                        onMouseLeave={() => setIsMouseOnSection(false)}
                      >
                        <span>
                          {index + 1 > 9 ? index + 1 : `0${index + 1}`}
                        </span>
                        <h2>{title}</h2>
                      </div>

                      <div className="details">
                        <span className="type">{type}</span>
                        <span className="category">{category}</span>
                      </div>
                    </li>
                  )}
                </Link>
              )
            })}
          </ul>
        </div>

        <section id="contact" className="s-contact bg-black">
          <div
            data-scroll
            data-scroll-speed="-2"
            data-scroll-direction="vertical"
          >
            <div className="c-rse max-container">
              <p>
                Pursuing ISO 20121 certification, we craft visuals that respect
                the planet, inspire change, and drive progress.
                <br className="br2" /> <br className="br1" /> Drop us a line to
                collaborate.
              </p>
            </div>
            <ContactInfos />
          </div>
        </section>
      </div>
    </>
  )
}

export default WorksList
