import React from "react"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

function Footer({ background }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <footer data-scroll-section className={`footer ${background}`}>
        <ul>
          <li className="link noSelect">
            <Link to="/legals">Legal terms</Link>
          </li>
          <li className="link noSelect">
            <Link to="/">©Blue Node {new Date().getFullYear()}</Link>
          </li>
        </ul>
      </footer>
    </motion.div>
  )
}

export default Footer
