const projects = () => {
  const projectsData = [
    {
      client: "Silent Partners Studio for TikTok",
      coproduction: "TITLLE COPROD",
      url: "camila-cabello-tiktok",
      title: "TikTok - Camila Cabello's Live Concert on TikTok",
      summary:
        "3D Production and XR Screen Contents for two songs of Camila Cabello's Live Concert on TikTok (La Buena Vida & Bam Bam).",
      metaTitle: "Camila Cabello - Live concert on TikToK",
      metaDescription: "Creation of two 3D scenes for a concert live on TikTok",
      cover: "bn-camila-cabello-cover",
      preview: "bn-camila-cabello-loop",
      previewPoster: "bn-camila-cabello-loop-preview",
      video: "bn-camila-cabello",
      category: "AR | XR |MR",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Blender", "3DS Max", "Unreal Engine"],
      infos: {
        image: "XXX",
        roles: ["Digital environment", "XR environment", "Extended reality"],
        credits: [
          "Artist : Camila Cabello",
          "XR Environments Creative Direction : Silent Partners Studio",
          "Scenes Design : Blue Node Paris",
          "XR Production : XR Studios",
        ],
      },
    },
    {
      client: "Envy Create for Ubisoft",
      coproduction: null,
      url: "pressconference-ubisoft-22",
      title: "Ubisoft - XR Live Conference",
      summary:
        "XR shoot and 3D production for #UbisoftForward, the major gaming event of Ubisoft.",
      metaTitle: "Ubisoft - XR Live Conference",
      metaDescription:
        "Extended Reality live press conference shot at Pure View XR studio.",
      cover: "image-cover-ubisoft-forward",
      preview: "ubisoft-forward-22-loop",
      previewPoster: "ubisoft-forward-22-loop-preview",
      video: "ubisoft-forward-live-conference",
      category: "Live Press Conference",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Unreal Engine", "Blender"],
      infos: {
        image: "XXX",
        roles: ["Digital environments", "XR environment", "Extended reality"],
        credits: [
          "Agency : Envy Create for Ubisoft",
          "Creative Direction : Adrian Le Mans",
          "XR Content : Blue Node Paris",
          "Production : Pure View XR Studio",
        ],
      },
    },
    {
      client: "Louis Vuitton",
      coproduction: null,
      url: "louis-vuitton-ss22",
      title: "Louis Vuitton - Eyewear SS22",
      summary:
        "XR shoot for the 2022 sunglasses collection by Louis Vuitton worn by Milly Bobby Brown.",
      metaTitle: "Louis Vuitton - XR campaign",
      metaDescription:
        "Full Extended Reality fashion campaign shot at Pure View XR studio.",
      cover: "louis-vuitton-ss22-cover",
      preview: "louis-vuitton-ss22-loop",
      previewPoster: "louis-vuitton-ss22-loop-preview",
      video: "louis-vuitton-ss22",
      category: "Campaign & Brand Content",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Unreal Engine", "nDisplay"],
      infos: {
        image: "XXX",
        roles: [
          "Digital Environment",
          "Virtual Production",
          "Real-time Content",
        ],
        credits: [
          "Creative Direction: Maybe",
          "Direction: Benn Northover",
          "DOP: Romain Alary",
          "Video Production: New Light Films",
          "XR Production: Pure View XR Studio",
          "Post: Nighshift",
        ],
      },
    },
    {
      client: "We are 360 - Warner Music",
      coproduction: null,
      title: "Soprano - XR Livestream",
      summary:
        "Blue Node makes here a live performance in XR for the french artist Soprano. He literally took his fans with him into space!",
      url: "soprano",
      metaTitle: "Soprano - XR livestream",
      metaDescription:
        "Extended Reality Live music session with 4 different XR environments shot in one day at Pure View XR studio.",
      cover: "soprano-1",
      preview: "soprano-loop",
      previewPoster: "soprano-loop-preview",
      video: "soprano",
      category: "Artistic Collaboration",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Unreal Engine", "Blender", "C4D", "Disguise"],
      infos: {
        image: "XXX",
        roles: [
          "Creative Direction",
          "XR environments",
          "Extended reality",
          "Augmented Reality",
          "Direction",
          "Edit",
        ],
        credits: [
          "Direction: Julien Faustino",
          "DOP : Julien Jaunet",
          "Edit : Julien Neuveglise",
          "Production : Pure View XR Studio",
        ],
      },
    },
    {
      client: "DMLS TV - M6",
      coproduction: null,
      title: "Vitaa & Slimane - XR Live Performance",
      summary:
        "Live performance by french duo Vitaa & Slimane, shot in XR for M6 television.",
      url: "vitaa-slimane-m6",
      metaTitle: "Vitaa & Slimane",
      metaDescription: "",
      cover: "vitaa-slimane-cover",
      preview: "vitaa-slimane-loop",
      previewPoster: "vitaa-slimane-loop-preview",
      video: "vitaa-slimane",
      category: "Artistic Collaboration",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Unreal Engine", "Blender", "C4D", "Disguise"],
      infos: {
        image: "XXX",
        roles: [
          "Digital environments",
          "Extended reality",
          "Augmented Reality",
        ],

        credits: [
          "Direction : Julien Faustino",
          "DOP : Frédéric Dorieux",
          "Creative Direction : D/Labs",
          "Production : DMLS TV",
          "XR Production : Virtual Display Services",
        ],
      },
    },
    {
      client: "Blue Node Lab",
      coproduction: null,
      title: "Sneakers Metaverse Experience",
      summary:
        "First metaverse experience using cloud computing through your web mobile. More to come soon!",
      url: "bn-sneakers-experience",
      metaTitle: "Sneakers Metaverse Experience",
      metaDescription: "",
      cover: "bn-sneakers-experience-cover",
      preview: "bn-sneakers-experience-loop",
      previewPoster: "bn-sneakers-experience-loop-preview",
      video: "bn-sneakers-experience",
      category: "Experiential platform",
      type: "Lab",
      highlight: true,
      technicalTags: [
        "Houdini",
        "Unreal Engine",
        "Blender",
        "3DS Max",
        "React.js",
        "WebRTC",
        "Cloud Computing",
      ],
      infos: {
        image: "XXX",
        roles: [
          "Creative Direction",
          "3D environments",
          "Coding",
          "Game Design",
        ],
        credits: ["For us, by us :)"],
      },
    },
    {
      client: "Coperni",
      coproduction: null,
      url: "campaign-coperni-ss21",
      title: "Coperni - SS21 Campaign",
      summary:
        "Blue Node brought to life the summer/fall 2021 collection for Coperni brand. Intense and deep, live the Coperni experience in its entirety through a XR shoot.",
      metaTitle: "Coperni - XR campaign",
      metaDescription:
        "Full Extended Reality fashion campaign shot at Pure View XR studio.",
      cover: "image-cover-coperni",
      preview: "campaign-coperni-ss21-loop",
      previewPoster: "campaign-coperni-ss21-loop-preview",
      video: "campaign-coperni-ss21",
      category: "Campaign & Brand Content",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Unreal Engine", "Blender", "C4D", "SMODE"],
      infos: {
        image: "XXX",
        roles: [
          "Digital environment",
          "Creative content",
          "XR environment",
          "Extended reality",
        ],
        credits: [
          "Creative Direction : Maybe",
          "Direction : We are from L.A.",
          "DOP : Arnaud Potier",
          "XR production : Pure View XR studio",
          "Production : Iconoclast image",
        ],
      },
    },
    {
      client: "Parfums Christian Dior",
      coproduction: null,
      title: "Miss Dior La Colle Noire",
      summary:
        "Watch the virtual scenographic creation to discover La Colle Noir through an immersive experience with video mapping and sensors for Dior perfume collection.",
      url: "miss-dior",
      metaTitle: "Miss Dior - Interactive Installation",
      metaDescription:
        "Interactive experience mixing realtime contents, sensors and video mapping and spatial sound.",
      cover: "dior-collenoire-1",
      preview: "dior-collenoire-loop",
      previewPoster: "dior-collenoire-loop-preview",
      video: "dior-collenoire",
      category: "Interactive installation",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Smode"],
      infos: {
        image: "XXX",
        roles: ["Concept", "Creative direction", "Digital scenography"],
        credits: [
          "Scenography : Golden Mama",
          "Production : Golden Mama",
          "Development : D/LABS",
        ],
      },
    },
    {
      client: null,
      coproduction: "Initial Artist - Universal x Blue Node",
      title: "Eddy de Pretto - Désolé Caroline (Live)",
      summary:
        "Creative direction and direction of a XR live performance for Eddy de Pretto - Désolé Caroline.",
      url: "eddy-de-pretto",
      metaTitle: "Eddy de Pretto - Live XR",
      metaDescription:
        "Music session using XR technology to deliver a stunning live performance from Eddy de Pretto.",
      cover: "eddydepretto-desolecaroline-1",
      preview: "eddy-de-pretto-loop",
      previewPoster: "eddy-de-pretto-loop-preview",
      video: "eddydepretto-desolecaroline-1",
      category: "Artistic Collaboration",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Unreal Engine", "Smode"],
      infos: {
        image: "XXX",
        roles: [
          "Direction",
          "Creative Direction",
          "XR environment",
          "Live AR particules",
          "Edit",
          "Grading",
        ],
        credits: [
          "DOP : Arthur Cemin",
          "Edit : Caroline Grastilleur",
          "Production : Pure View XR Studio",
        ],
      },
    },
    {
      client: "DMLS TV - M6",
      coproduction: null,
      url: "julien-dore-m6",
      title: "Julien Doré - XR Live Performance",
      summary: "Like Vitaa & Slimane a XR shot for Julien Doré and M6.",
      metaTitle: "Julein Doré - XR Live Performance",
      metaDescription: "Full Extended Reality music video.",
      cover: "julien-dore-m6-cover",
      preview: "julien-dore-m6-loop",
      previewPoster: "julien-dore-m6-loop-preview",
      video: "julien-dore-m6",
      category: "Artistic Collaboration",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Unreal Engine", "Disguise"],
      infos: {
        image: "XXX",
        roles: [
          "Digital environments",
          "Extended reality",
          "Augmented Reality",
        ],
        credits: [
          "Direction : Julien Faustino",
          "DOP : Frédéric Dorieux",
          "Creative Direction : D/Labs",
          "Production : DMLS TV",
          "XR Production : Virtual Display Services",
        ],
      },
    },
    {
      client: "Napoléon Studio",
      coproduction: null,
      url: "live-by-blue-beauty",
      metatitle: "Biotherm - Immersive web platform",
      metaDescription:
        "Spatial web and webgl to deliver a fully immersive platform broadcasted worldwide for Biotherm PR launch.",
      title: "Biotherm - Live by blue beauty",
      summary:
        "Interactive and immersive world for Biotherm PR launch. The beginning of the metaverse.",
      cover: "biotherm-livebybeauty-1",
      preview: "live-by-blue-beauty-loop",
      previewPoster: "live-by-blue-beauty-loop-preview",
      video: "biotherm",
      category: "Experiential Platform",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Unreal Engine", "WebGl"],
      infos: {
        image: "XXX",
        roles: ["Concept", "Web design", "Platform development"],
        credits: ["Creative Direction : Vangard"],
      },
    },
    {
      client: "DMLS TV - M6",
      coproduction: null,
      title: "La Soirée Extraordinaire - M6",
      summary:
        "First ever French TV show shot in XR for M6 broadcaster. 43 Extended Reality and Augmented Reality scenes shot in 4 days.",
      url: "la-soiree-extraordinaire",
      metaTitle: "Soirée Extraordinaire - XR Broadcast",
      metaDescription:
        "European premiere XR broadcast music program. 43 Extended Reality and Augmented Reality scenes shot in 4 days.",
      cover: "dmls-lasoireeextraordinaire-1",
      preview: "dmls-lasoireeextraordinaire-loop",
      previewPoster: "dmls-lasoireeextraordinaire-loop-preview",
      video: "dmls-lasoireeextraordinaire",
      category: "AR | XR | MR",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Unreal Engine", "Disguise"],
      infos: {
        image: "image-coperni-1.jpg",
        roles: [
          "Digital environments",
          "Extended reality",
          "Augmented Reality",
        ],
        credits: [
          "Direction : Julien Faustino",
          "DOP : Frédéric Dorieux",
          "Creative Direction : D/Labs",
          "Production : DMLS TV",
          "XR Production : Virtual Display Services",
        ],
      },
    },
    {
      client: "Napoléon Studio",
      coproduction: null,
      url: "renergie-triple-formula",
      metaTitle: "Lancôme - Immersive web platform",
      metaDescription:
        "Metaverse web platform delivered using three.js and webgl tools for Lancôme press launch.",
      title: "Lancôme - Rénergie Triple Formula",
      summary:
        "Another fully immersive WebGL experience for Lancôme to catch-up PR and US customers.",
      cover: "lancome",
      preview: "renergie-triple-formula-loop",
      previewPoster: "renergie-triple-formula-loop-preview",
      video: "lancome",
      category: "Experiential platform",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Three.js", "WebGl", "React.js"],
      infos: {
        image: "XXX",
        roles: ["Web design", "Platform development"],
        credits: ["Creative Direction : Vangard"],
      },
    },
    {
      client: "Parfums Christian Dior",
      coproduction: null,
      url: "dior-booth",
      title: "Dior Infinite Booth",
      summary:
        "Multisensory experience mixing synchronized and encoded lights, motion design, sensors and spatial audio, created for Dior perfumes.",
      metaTitle: "Dior - Infinite booth",
      metaDescription:
        "Ultra immersive installation playing with sensors, led tailor made set up, video screens and one way mirrors to display a full motion and light design journey.",
      cover: "dior-chinafair-1",
      preview: "dior-chinafair-loop",
      previewPoster: "dior-chinafair-loop-preview",
      video: "dior-chinafair-1",
      category: "Immersive installation",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Unreal Engine"],
      infos: {
        image: "XXX",
        roles: [
          "Concept",
          "Creative direction",
          "Digital scenography",
          "Motion design",
          "Light Design",
        ],
        credits: [
          "Scenography : Golden Mama",
          "Production : Golden Mama",
          "Development : D/LABS",
        ],
      },
    },
    {
      client: null,
      coproduction: "Quiet Club x Blue Node",
      title: "Owlle - XR Music Video",
      summary:
        "See the full immersion of Owlle in this lushly mountain environment, shot in XR.",
      url: "owlle",
      metaTitle: "Owlle",
      metaDescription: "",
      cover: "owlle-cover",
      preview: "owlle-loop",
      previewPoster: "owlle-loop-preview",
      video: "owlle",
      category: "Artistic Collaboration",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Unreal Engine"],
      infos: {
        image: "XXX",
        roles: ["Creative Direction", "Direction", "XR environments"],
        credits: [
          "Art Direction : Owlle, Gourauphong",
          "Management : Savoir Faire",
          "Production : Pure View XR Studio",
          "DOP : Julien Jaunet",
          "Edit : Tianès Montasser",
        ],
      },
    },
    /*
    {
      client: "Camelia Cabello",
      coproduction: null,
      url: "camelia-cabello",
      title: "Camelia Cabello - XR Music Video",
      summary:
        "Creation and shoot of XR assets for Camelia Cabello - Welcome to the Family music video.",
      metaTitle: "Camelia Cabello - XR Music Video",
      metaDescription: "Full Extended Reality music video.",
      cover: "camelia-cabello-cover",
      preview: "camelia-cabello-loop",
      previewPoster: "camelia-cabello-loop-preview",
      video: "camelia-cabello",
      category: "AR | XR | MR",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Unreal Engine", "Blender", "3DS Max"],
      infos: {
        image: "XXX",
        roles: ["Digital environment"],
        credits: ["Creative Direction :"],
      },
    },
    */

    /*
    {
      client: "Silent Partners Studio",
      coproduction: null,
      url: "sps-wildrift",
      title: "Wild Rift Icons 2022 - Esport Competition",
      summary:
        "Creation of 3D assets for the stage of Wild Rift 2022 Esport Competition.",
      metaTitle: "Wild Rift Icons 2022",
      metaDescription: "",
      cover: "sps-wildrift-cover",
      preview: "sps-wildrift-loop",
      previewPoster: "sps-wildrift-loop-preview",
      video: "sps-wildrift",
      category: "AR | XR | MR",
      type: "Commissioned",
      highlight: true,
      technicalTags: ["Houdini", "Houdini Engine", "Unreal Engine"],
      infos: {
        image: "XXX",
        roles: ["Digital environment"],
        credits: ["Creative Direction :"],
      },
    },
    */
  ]

  return projectsData
}

const homepage = () => {
  const HomepageData = [
    {
      title: "Playground",
      elements: [
        "Experiential platforms",
        "Virtual replica",
        "AR | XR | MR",
        "Campaigns & brand content",
        "Immersive & interactive installations",
        "Artistic collaborations",
      ],
    },
    {
      title: "Skills",
      elements: [
        "Art direction",
        "3D modeling",
        "Creative coding",
        "Motion design",
        "Gaming",
        "R&D",
      ],
    },
  ]

  return HomepageData
}

const clients = () => {
  const clientsData = [
    {
      title: "Clients & Partners",
      elements: [
        "Universal Music",
        "Warner",
        "BMG",
        "Wagram",
        "Louis Vuitton",
        "Coperni",
        "Dior",
        "CANAL +",
        "M6",
        "Art+Commerce",
        "Iconoclast",
        "Eddy de Pretto",
        "Soprano",
        "Suzane",
        "Owlle",
        "Ubisoft",
        "Golden Mama",
        "Silent Partners Studio",
        "Napoléon Studio",
      ],
    },
  ]

  return clientsData
}

export { projects, homepage, clients }
